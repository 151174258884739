<template>
  <div v-frag>
    <template v-for="(item, index) in pageContent">
      <component :is="item.elementName" :element-props="item.elementProperties" :key="index"></component>
    </template>
  </div>
</template>

<script>
export default {
  name: 'JobPage',
  components: {
    //
  },
  async beforeMount() {
    const url = await this.UrlDecoder();
    await this.GetPageContent(url);
  },
  props: {
    //
  },
  data() {
    return {
      pageContent: [],
    };
  },
  methods: {
    async UrlDecoder() {
      const pageUrl = this.$route.path.replace('/', '');
      const lastChar = pageUrl.charAt(pageUrl.length - 1);
      let urlToSend = pageUrl;
      if (lastChar === '/') {
        urlToSend = pageUrl.substr(0, pageUrl.length - 1);
      }
      return urlToSend;
    },
    async GetPageContent(url) {
      const response = await this.$store.dispatch('showJobByUrl', url);
      if (response.status === 200) {
        this.pageContent = JSON.parse(response.data.contentJson);
      } else {
        this.$router.push({ name: '404-oldal' });
      }
    },
  },
};
</script>

<style scoped>

</style>
